import React from 'react'
import Layout from 'layouts/en'
import Template from 'templates/terms-and-conditions'

export default () => {
  return (
    <Layout p={5}>
      <Template lang="en" content={content} />
    </Layout>
  )
}

const content = (
  <div className="template">
    <h1 className="headline"> Terms and Conditions </h1>{" "}
    <div className="paragraph">
      <p className="text">
        {" "}
        Terms and Conditions Last updated: July 08, 2017 <br /> These Terms and
        Conditions("Terms", "Terms and Conditions") govern your relationship
        with Stairlin mobile application and websites(the "Service") operated by
        Stairlin AG("us", "we", or "our"). <br /> Please read these Terms and
        Conditions carefully before using our Stairlin mobile application and
        websites(the "Service"). <br /> Your access to and use of the Service is
        conditioned on your acceptance of and compliance with these Terms.These
        Terms apply to all visitors, users and others who access or use the
        Service. <br /> By accessing or using the Service you agree to be bound
        by these Terms.If you disagree with any part of the terms then you may
        not access the Service.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Purchases </p>{" "}
      <p className="text">
        {" "}
        If you wish to purchase any product or service made available through
        the Service("Purchase"), you may be asked to supply certain information
        relevant to your Purchase including, without limitation, your credit
        card number, the expiration date of your credit card, your billing
        address, and your shipping information. <br /> You represent and warrant
        that: (i) you have the legal right to use any credit card(s) or other
        payment method(s) in connection with any Purchase; and that(ii) the
        information you supply to us is true, correct and complete. <br /> By
        submitting such information, you grant us the right to provide the
        information to third parties for purposes of facilitating the completion
        of Purchases. <br /> We reserve the right to refuse or cancel your order
        at any time for certain reasons including but not limited to: product or
        service availability, errors in the description or price of the product
        or service, error in your order or other reasons. <br /> We reserve the
        right to refuse or cancel your order if fraud or an unauthorised or
        illegal transaction is suspected.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Availability, Errors and Inaccuracies </p>{" "}
      <p className="text">
        {" "}
        We are constantly updating our offerings of products and services on the
        Service.The products or services available on our Service may be
        mispriced, described inaccurately, or unavailable, and we may experience
        delays in updating information on the Service and in our advertising on
        other web sites. <br /> We cannot and do not guarantee the accuracy or
        completeness of any information, including prices, product images,
        specifications, availability, and services.We reserve the right to
        change or update information and to correct errors, inaccuracies, or
        omissions at any time without prior notice.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Contests, Sweepstakes and Promotions </p>{" "}
      <p className="text">
        {" "}
        Any contests, sweepstakes or other promotions(collectively,
        "Promotions") made available through the Service may be governed by
        rules that are separate from these Terms.If you participate in any
        Promotions, please review the applicable rules as well as our Privacy
        Policy.If the rules for a Promotion conflict with these Terms, the
        Promotion rules will apply.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Subscriptions </p>{" "}
      <p className="text">
        {" "}
        Some parts of the Service are billed on a subscription
        basis("Subscription(s)").You will be billed in advance on a recurring
        and periodic basis("Billing Cycle").Billing cycles are set either on a
        monthly or annual basis, depending on the type of subscription plan you
        select when purchasing a Subscription. <br /> At the end of each Billing
        Cycle, your Subscription will automatically renew under the exact same
        conditions unless you cancel it or Stairlin AG cancels it.You may cancel
        your Subscription renewal either through your online account management
        page or by contacting Stairlin AG customer support team. <br /> A valid
        payment method, including credit card or PayPal, is required to process
        the payment for your Subscription.You shall provide Stairlin AG with
        accurate and complete billing information including full name, address,
        state, zip code, telephone number, and a valid payment method
        information.By submitting such payment information, you automatically
        authorize Stairlin AG to charge all Subscription fees incurred through
        your account to any such payment instruments. <br /> Should automatic
        billing fail to occur for any reason, Stairlin AG will issue an
        electronic invoice indicating that you must proceed manually, within a
        certain deadline date, with the full payment corresponding to the
        billing period as indicated on the invoice.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Free Trial </p>{" "}
      <p className="text">
        {" "}
        Stairlin AG, in its sole discretion and at any time, may modify the
        Subscription fees for the Subscriptions.Any Subscription fee change will
        become effective at the end of the then - current Billing Cycle. <br />{" "}
        Stairlin AG will provide you with a reasonable prior notice of any
        change in Subscription fees to give you an opportunity to terminate your
        Subscription before such change becomes effective. <br /> Your continued
        use of the Service after the Subscription fee change comes into effect
        constitutes your agreement to pay the modified Subscription fee amount.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Fee Changes </p>{" "}
      <p className="text">
        {" "}
        Stairlin AG, in its sole discretion and at any time, may modify the
        Subscription fees for the Subscriptions.Any Subscription fee change will
        become effective at the end of the then - current Billing Cycle. <br />{" "}
        Stairlin AG will provide you with a reasonable prior notice of any
        change in Subscription fees to give you an opportunity to terminate your
        Subscription before such change becomes effective. <br /> Your continued
        use of the Service after the Subscription fee change comes into effect
        constitutes your agreement to pay the modified Subscription fee amount.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Refunds </p>{" "}
      <p className="text">
        {" "}
        Except when required by law, paid Subscription fees are non -
        refundable.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Publicly Shared Content </p>{" "}
      <p className="text">
        {" "}
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other
        material("Content").You are responsible for the Content that you post to
        the Service, including its legality, reliability, and appropriateness.{" "}
        <br /> By posting Content to the Service, you grant us the right and
        license to use, modify, publicly perform, publicly display, reproduce,
        and distribute such Content on and through the Service.You retain any
        and all of your rights to any Content you submit, post or display on or
        through the Service and you are responsible for protecting those
        rights.You agree that this license includes the right for us to make
        your Content available to other users of the Service, who may also use
        your Content subject to these Terms. <br /> You represent and warrant
        that: (i) the Content is yours(you own it) or you have the right to use
        it and grant us the rights and license as provided in these Terms,
        and(ii) the posting of your Content on or through the Service does not
        violate the privacy rights, publicity rights, copyrights, contract
        rights or any other rights of any person.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Private Content </p>{" "}
      <p className="text">
        {" "}
        Content saved on our platform that is not intended to be publicly
        shared, especially customer informations, notes, pictures and videos are
        securely stored and not visible to anyone but you.You alone have the
        capacity to access, modify or delete this information.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Accounts </p>{" "}
      <p className="text">
        {" "}
        When you create an account with us, you must provide us information that
        is accurate, complete, and current at all times.Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of your account on our Service. <br /> You are responsible
        for safeguarding phone number(and / or password and email) that you use
        to access the Service and for any activities or actions under your
        password, whether your phone number(and / or password and email) is with
        our Service or a third - party service. <br /> You agree not to disclose
        your password to any third party.You must notify us immediately upon
        becoming aware of any breach of security or unauthorized use of your
        account.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Intellectual Property </p>{" "}
      <p className="text">
        {" "}
        The Service and its original content(excluding Content provided by
        users), features and functionality are and will remain the exclusive
        property of Stairlin AG and its licensors.The Service is protected by
        copyright, trademark, and other laws of both the Switzerland and foreign
        countries.Our trademarks and trade dress may not be used in connection
        with any product or service without the prior written consent of
        Stairlin AG.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Links To Other Web Sites </p>{" "}
      <p className="text">
        {" "}
        Our Service may contain links to third - party web sites or services
        that are not owned or controlled by Stairlin AG. <br /> Stairlin AG has
        no control over, and assumes no responsibility for, the content, privacy
        policies, or practices of any third party web sites or services.You
        further acknowledge and agree that Stairlin AG shall not be responsible
        or liable, directly or indirectly, for any damage or loss caused or
        alleged to be caused by or in connection with use of or reliance on any
        such content, goods or services available on or through any such web
        sites or services. <br /> We strongly advise you to read the terms and
        conditions and privacy policies of any third - party web sites or
        services that you visit.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Termination </p>{" "}
      <p className="text">
        {" "}
        We may terminate or suspend your account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. <br /> Upon termination, your right
        to use the Service will immediately cease.If you wish to terminate your
        account, you may simply discontinue using the Service.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Limitation Of Liability </p>{" "}
      <p className="text">
        {" "}
        In no event shall Stairlin AG, nor its directors, employees, partners,
        agents, suppliers, or affiliates, be liable for any indirect,
        incidental, special, consequential or punitive damages, including
        without limitation, loss of profits, data, use, goodwill, or other
        intangible losses, resulting from(i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of
        any third party on the Service; (iii) any content obtained from the
        Service; and(iv) unauthorized access, use or alteration of your
        transmissions or content, whether based on warranty, contract,
        tort(including negligence) or any other legal theory, whether or not we
        have been informed of the possibility of such damage, and even if a
        remedy set forth herein is found to have failed of its essential
        purpose.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Disclaimer </p>{" "}
      <p className="text">
        {" "}
        Your use of the Service is at your sole risk.The Service is provided on
        an "AS IS" and "AS AVAILABLE" basis.The Service is provided without
        warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, non - infringement or course of performance. <br />{" "}
        Stairlin AG its subsidiaries, affiliates, and its licensors do not
        warrant that a) the Service will function uninterrupted, secure or
        available at any particular time or location; b) any errors or defects
        will be corrected; c) the Service is free of viruses or other harmful
        components; or d) the results of using the Service will meet your
        requirements.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Governing Law </p>{" "}
      <p className="text">
        {" "}
        These Terms shall be governed and construed in accordance with the laws
        of Switzerland, without regard to its conflict of law provisions. <br />{" "}
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights.If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect.These Terms constitute the entire
        agreement between us regarding our Service, and supersede and replace
        any prior agreements we might have between us regarding the Service.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Changes </p>{" "}
      <p className="text">
        {" "}
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time.If a revision is material we will try to provide at
        least 30 days notice prior to any new terms taking effect.What
        constitutes a material change will be determined at our sole discretion.{" "}
        <br /> By continuing to access or use our Service after those revisions
        become effective, you agree to be bound by the revised terms.If you do
        not agree to the new terms, please stop using the Service.{" "}
      </p>{" "}
    </div>{" "}
    <div className="paragraph">
      <p className="text bold"> Contact Us </p>{" "}
      <p className="text">
        {" "}
        If you have any questions about these Terms, please contact us.{" "}
      </p>{" "}
    </div>
  </div>
);
